export const ENVIRONMENTS = {
  development: "development",
  staging: "staging",
  production: "production",
  localProduction: "localProduction",
  local: "localBuild",
};

export const LOCAL_STORAGE = {
  accessToken: "access_token",
  fbIdToken: "id_token",
  userId: "user_id",
  recentMobileVerification: "recentMobileVerification",
  permissionRequestAskedInSession: "permissionRequestAskedInSession",
  onboardedUserId: "onBoardedUserId",
  userName: "user_name",
  userEmail: "user_email",

  // sign up save
  firstName: "first_name",
  lastName: "last_name",
  emailAddress: "emailAddress",

  // session
  sessionId: "sessionId",
};

export const RESIDENTIAL_STATES = [
  {
    value: "VIC",
    label: "VIC",
  },
  {
    value: "NSW",
    label: "NSW",
  },
  {
    value: "QLD",
    label: "QLD",
  },
  {
    value: "TAS",
    label: "TAS",
  },
  {
    value: "WA",
    label: "WA",
  },
  {
    value: "NT",
    label: "NT",
  },
  {
    value: "SA",
    label: "SA",
  },
  {
    value: "ACT",
    label: "ACT",
  },
];

export const SUBSCRIPTION_TRANSACTION_LABEL = {
  INITIAL_PURCHASE: "Subscription",
  RENEWAL: "Subscription",
  CANCELLATION: "Cancellation",
  PRODUCT_CHANGE: "Update",
  EXPIRATION: "Expiration",
  BILLING_ISSUE: "Billing Issue",
  TRANSFER: "Subscription", // do not show this
};

export const SUBSCRIPTION_STRIPE_TRANSACTION_LABEL = {
  "invoice.paid": "Subscription",
  "customer.subscription.deleted": "Cancellation",
  "invoice.payment_action_required": "Billing Issue",
  "invoice.payment_failed": "Billing Issue",
  "customer.subscription.paused": "Billing Issue",
  "customer.subscription.resumed": "Billing Issue",
};
export const SUBSCRIPTION_IDS = [
  "prod_OfnxxkAvE8cWuz",
  "product.starter",
  "winners_locker_subscription_1",
  "winners_locker_subscription_starter",
  "prod_OfnKDhYc4NAP8c",
  "winners_locker_subscription_starterpack",

  "prod_OfnwpnIiyyMlm6",
  "product.pro",
  "winners_locker_subscription_2",
  "winners_locker_subscription_pro",
  "prod_OfnJe0DVMHStYK",
  "winners_locker_subscription_professional",

  "prod_Ofnv735IcfvmfZ",
  "product.elite",
  "winners_locker_subscription_3",
  "winners_locker_subscription_elite",
  "prod_OfnJDStYYnSzbC",
  "product.mvp",
  "winners_locker_subscription_mvp",

  //VIP
  "prod_P90uZJToKnuMNM",
  "prod_PAMzgb4vJmu1ND",

  //LEGEND
  "prod_QxjMortOKdk8I9",
  "prod_Qxk26YL2gXplr0",
];

export const HARDCODED_SUBS_PRICING: { [id: string]: number } = {
  prod_OfnxxkAvE8cWuz: 19,
  "product.starter": 19,
  winners_locker_subscription_1: 19,
  winners_locker_subscription_starter: 19,
  prod_OfnKDhYc4NAP8c: 19,
  winners_locker_subscription_starterpack: 19,

  prod_OfnwpnIiyyMlm6: 39,
  "product.pro": 39,
  winners_locker_subscription_2: 39,
  winners_locker_subscription_pro: 39,
  prod_OfnJe0DVMHStYK: 39,
  winners_locker_subscription_professional: 39,

  prod_Ofnv735IcfvmfZ: 69,
  "product.elite": 69,
  winners_locker_subscription_3: 69,
  winners_locker_subscription_elite: 69,
  prod_OfnJDStYYnSzbC: 69,
  "product.mvp": 69,
  winners_locker_subscription_mvp: 69,

  //VIP
  prod_P90uZJToKnuMNM: 649,
  prod_PAMzgb4vJmu1ND: 649,

  //LEGEND
  prod_QxjMortOKdk8I9: 149,
  prod_Qxk26YL2gXplr0: 149,

  // ONE OFFS dev & STG
  prod_P5IJ3AJgxyuTa3: 25,
  prod_P5IKBfL3SLEMp4: 50,
  prod_P5IKoKavViIdnl: 100,
  prod_P5IKESqzqK3teK: 250,
  prod_P5ILlSKENy2qbh: 500,

  //ONE OFFS PROD
  prod_P7sLth1ZW3yZWb: 25,
  prod_P7sLLd2nWbkuau: 50,
  prod_P7sLdnt4lcWr0A: 100,
  prod_P7sLt0PwA4ME0X: 250,
  prod_P7sL0ZasDFDBeq: 500,
};

export const STRIPE_SUBSCRIPTION_ID_MAP_DEV_STG: { [key: string]: string } = {
  starter: "prod_OfnxxkAvE8cWuz", // dev starter

  pro: "prod_OfnwpnIiyyMlm6", // dev pro

  mvp: "prod_Ofnv735IcfvmfZ", // dev mvp
};
export const STRIPE_SUBSCRIPTION_ID_MAP: { [key: string]: string } = {
  starter: "prod_OfnKDhYc4NAP8c", // prod starter
  pro: "prod_OfnJe0DVMHStYK", // prod pro
  mvp: "prod_OfnJDStYYnSzbC", //  prod mvp
};

export const STRIPE_SUBSCRIPTION_NAME_MAP: { [key: string]: string } = {
  prod_OfnxxkAvE8cWuz: "Starter", // dev starter

  prod_OfnwpnIiyyMlm6: "Pro", // dev pro

  prod_Ofnv735IcfvmfZ: "MVP", // dev mvp

  prod_OfnKDhYc4NAP8c: "Starter", // prod starter
  prod_OfnJe0DVMHStYK: "Pro", // prod pro
  prod_OfnJDStYYnSzbC: "MVP", //  prod mvp
};

export type SubSource = "stripe" | "ios" | "android";
export const HARDCODED_SUBS_SOURCE: { [id: string]: SubSource } = {
  prod_OfnxxkAvE8cWuz: "stripe",
  "product.starter": "android",
  winners_locker_subscription_1: "ios",
  winners_locker_subscription_starter: "ios",
  prod_OfnKDhYc4NAP8c: "stripe",
  winners_locker_subscription_starterpack: "ios",

  prod_OfnwpnIiyyMlm6: "stripe",
  "product.pro": "android",
  winners_locker_subscription_2: "ios",
  winners_locker_subscription_pro: "ios",
  prod_OfnJe0DVMHStYK: "stripe",
  winners_locker_subscription_professional: "ios",

  prod_Ofnv735IcfvmfZ: "stripe",
  "product.elite": "android",
  winners_locker_subscription_3: "ios",
  winners_locker_subscription_elite: "ios",
  prod_OfnJDStYYnSzbC: "stripe",
  "product.mvp": "android",
  winners_locker_subscription_mvp: "ios",

  //VIP
  prod_P90uZJToKnuMNM: "stripe",
  prod_PAMzgb4vJmu1ND: "stripe",

  //LEGEND
  prod_QxjMortOKdk8I9: "stripe",
  prod_Qxk26YL2gXplr0: "stripe",

  // ONE OFFS dev & stg
  prod_P5IJ3AJgxyuTa3: "stripe",
  prod_P5IKBfL3SLEMp4: "stripe",
  prod_P5IKoKavViIdnl: "stripe",
  prod_P5IKESqzqK3teK: "stripe",
  prod_P5ILlSKENy2qbh: "stripe",

  //ONE OFFS PROD
  prod_P7sL0ZasDFDBeq: "stripe",
  prod_P7sLt0PwA4ME0X: "stripe",
  prod_P7sLdnt4lcWr0A: "stripe",
  prod_P7sLLd2nWbkuau: "stripe",
  prod_P7sLth1ZW3yZWb: "stripe",
};

export type ONE_OFF_TICKET_TYPES =
  | "bronze"
  | "silver"
  | "gold"
  | "platinum"
  | "diamond";

export const ONE_OFF_TICKET_TYPE: { [oneOffId: string]: ONE_OFF_TICKET_TYPES } =
  {
    // ONE OFFS dev & STG
    prod_P5IJ3AJgxyuTa3: "bronze",
    prod_P5IKBfL3SLEMp4: "silver",
    prod_P5IKoKavViIdnl: "gold",
    prod_P5IKESqzqK3teK: "platinum",
    prod_P5ILlSKENy2qbh: "diamond",

    //ONE OFFS PROD
    prod_P7sLth1ZW3yZWb: "bronze",
    prod_P7sLLd2nWbkuau: "silver",
    prod_P7sLdnt4lcWr0A: "gold",
    prod_P7sLt0PwA4ME0X: "platinum",
    prod_P7sL0ZasDFDBeq: "diamond",
  };

export const VIP_IDS = ["prod_P90uZJToKnuMNM", "prod_PAMzgb4vJmu1ND"];
export const LEGEND_IDS = ["prod_Qxk26YL2gXplr0", "prod_QxjMortOKdk8I9"];

export const MOBILE_NATIVE_TEST_ENABLED =
  [
    ENVIRONMENTS.local,
    ENVIRONMENTS.development,
    // ENVIRONMENTS.staging,
    // ENVIRONMENTS.production,
  ].indexOf(import.meta.env.MODE) !== -1;
