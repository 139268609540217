import isNull from "lodash/isNull";
import Stripe from "stripe";
import { confirmPasswordReset, sendPasswordResetEmail } from "firebase/auth";

import { bugNotif } from "../api/bugsnag";
import {
  auth,
  db,
  fbProvider,
  googleProvider,
  signInWithPopup,
  signInWithRedirect,
} from "../firebase";
import { setDoc, doc, getDoc } from "../../Common/utils/firebaseFunctions";
import { UserSessionCreate } from "../../Common/models/user";
import {
  getErrorMsg,
  hashStringToSHA256,
  isMobile,
} from "../../Common/utils/common";
import { getSubscription } from "./subscription";
import { ViewWLSubscriptionsTiers } from "../../Common/models/subscriptions";
import { ttqTrackEvent } from "../api/tiktokEvents";
import { getStripePriceValueWithPromo } from "./promo";
import { USER_SESSION } from "../constants/dbCollections";
import { getUserHashedDetails, trackWebSignUpSteps } from "../api/user";
import { WebSignUpSteps } from "../../Common/models/analytics";

export const signUpWithGoogle = async () => {
  const isMobileWeb = isMobile(true);
  const result = await (isMobileWeb ? signInWithPopup : signInWithPopup)(
    auth,
    googleProvider
  );
  // const credential = GoogleAuthProvider.credentialFromResult(result);
  // const token = credential?.accessToken;
  const user = result.user;

  console.log("User Info:", user);
  return user;
};

export const signUpWithFacebook = async () => {
  const isMobileWeb = isMobile(true);
  const result = await (isMobileWeb ? signInWithPopup : signInWithPopup)(
    auth,
    fbProvider
  );

  // const credential = GoogleAuthProvider.credentialFromResult(result);
  // const token = credential?.accessToken;
  const user = result.user;

  console.log("User Info:", user);
  return user;
};

export const sendResetPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const confirmResetPassword = async (code: string, password: string) => {
  await confirmPasswordReset(auth, code, password);
};

export const sendgtag = async (transactionId: string, value: number) => {
  try {
    dataLayer.push({
      event: "purchase",
      transaction_id: transactionId,
      value,
      currency: "AUD",
    });
  } catch (error) {
    console.log("error sendgtag", error);
    bugNotif("sendgtag", getErrorMsg(error));
  }
};

export const sendttqTracking = async (
  userId: string,
  pid: string,
  value: number
) => {
  const event_id = Math.random().toString(36).substring(2, 17);
  try {
    const {
      data: {
        result: { emailHashed, phoneNumberHashed, hashedUserId },
      },
    } = await getUserHashedDetails(userId);

    ttq.identify({
      email: emailHashed, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
      external_id: hashedUserId, // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
      phone_number: phoneNumberHashed,
    });

    const subscription = await getSubscription(pid);
    if (!isNull(subscription)) {
      ttq.track(
        "CompletePayment",
        {
          contents: [
            {
              content_id: subscription.docId, // string. ID of the product. Example: "1077218".
              content_type: "product", // string. Either product or product_group.
              content_name: subscription.name, // string. The name of the page or product. Example: "shirt".
            },
          ],
          value, // number. Value of the order or items sold. Example: 100.
          currency: "AUD", // string. The 4217 currency code. Example: "USD".
        },
        { event_id }
      );
    }
  } catch (error) {
    console.log("error sendttqTracking", error);
    bugNotif("sendttqTracking", getErrorMsg(error));
  }
  return event_id;
};

export const sendSnapTracking = async (
  userId: string,
  type: "Membership" | "One Off" | "Power Up",
  pid: string,
  value: number
) => {
  try {
    const {
      data: {
        result: { emailAddress },
      },
    } = await getUserHashedDetails(userId);

    snaptr("init", "5168c91f-aba8-405a-a9a9-f9f797c9fc2c", {
      user_email: emailAddress,
    });

    snaptr("track", "PURCHASE", {
      price: value,
      currency: "AUD",
      item_category: type,
      item_ids: [pid],
      transaction_id: `${userId}-${pid}-${new Date().valueOf()}`,
      client_dedup_id: Math.random().toString(36).substring(2, 17),
    });
  } catch (error) {
    console.log("error sendSnapTracking", error);
    bugNotif("sendSnapTracking", getErrorMsg(error));
  }
};

export const sendttqAddToCart = async (
  emailAddress: string,
  pid: string,
  value: number
) => {
  const event_id = Math.random().toString(36).substring(2, 17);
  try {
    const hashedEmail = await hashStringToSHA256(emailAddress);

    ttq.identify({
      email: hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
    });

    const subscription = await getSubscription(pid);
    if (!isNull(subscription)) {
      ttq.track(
        "AddToCart",
        {
          contents: [
            {
              content_id: subscription.docId, // string. ID of the product. Example: "1077218".
              content_type: "product", // string. Either product or product_group.
              content_name: subscription.name, // string. The name of the page or product. Example: "shirt".
            },
          ],
          value, // number. Value of the order or items sold. Example: 100.
          currency: "AUD", // string. The 4217 currency code. Example: "USD".
        },
        { event_id }
      );
    }
  } catch (error) {
    console.log("error sendttqAddToCart", error);
    bugNotif("sendttqAddToCart", getErrorMsg(error));
  }

  return event_id;
};

export const sendFBTracking = async (
  type: "Membership" | "One Off" | "Power Up",
  pid: string,
  value: number
) => {
  try {
    const subscription = await getSubscription(pid);
    if (!isNull(subscription)) {
      fbq(
        "track",
        "Purchase",
        {
          content_name: subscription.name,
          content_category: type,
          content_type: "product",
          content_ids: [subscription.docId],
          value: value,
          currency: "AUD",
        },
        { eventID: Math.random().toString(36).substring(2, 17) }
      );
    }
  } catch (error) {
    console.log("error sendFbqCart", error);
    bugNotif("sendFbqCart", getErrorMsg(error));
  }
};

export const sendFbqCart = async (
  type: "Membership" | "One Off" | "Power Up",
  pid: string,
  value: number
) => {
  try {
    const subscription = await getSubscription(pid);
    if (!isNull(subscription)) {
      fbq(
        "track",
        "AddToCart",
        {
          content_name: subscription.name,
          content_category: type,
          content_type: "product",
          content_ids: [subscription.docId],
          value: value,
          currency: "AUD",
        },
        { eventID: Math.random().toString(36).substring(2, 17) }
      );
    }
  } catch (error) {
    console.log("error sendFbqCart", error);
    bugNotif("sendFbqCart", getErrorMsg(error));
  }
};

export const sendSnapAddToCart = async (
  emailAddress: string,
  type: "Membership" | "One Off" | "Power Up",
  pid: string,
  value: number
) => {
  try {
    snaptr("init", "5168c91f-aba8-405a-a9a9-f9f797c9fc2c", {
      user_email: emailAddress,
    });

    snaptr("track", "ADD_CART", {
      price: value,
      currency: "AUD",
      item_category: type,
      item_ids: [pid],
      client_dedup_id: Math.random().toString(36).substring(2, 17),
    });
  } catch (error) {
    console.log("error sendSnapAddToCart", error);
    bugNotif("sendSnapAddToCart", getErrorMsg(error));
  }
};

export const createUserSession = async (userId: string, sessionId: string) => {
  const existingSession = await getDoc(doc(db, USER_SESSION, sessionId));
  if (existingSession.exists()) {
    return null;
  }
  await setDoc(doc(db, USER_SESSION, sessionId), {
    userId,
    sessionId,
    createdDt: new Date().valueOf(),
  } as UserSessionCreate);
};

//tiktok events api
export const sendTTQViewEvent = async (
  emailAddress: string,
  subscription: ViewWLSubscriptionsTiers
) => {
  try {
    const hashedEmail = await hashStringToSHA256(emailAddress);

    const tiktokSource = new URLSearchParams(window.location.search).get(
      "ttclid"
    );
    await ttqTrackEvent({
      event: "ViewContent",
      user: {
        email: hashedEmail,
        ...(!isNull(tiktokSource)
          ? {
              ttclid: tiktokSource,
            }
          : {}),
      },
      product: {
        id: subscription.docId,
        name: subscription.name,
      },
      url: location.href,
    });
  } catch (error) {
    bugNotif("sendTTQViewEvent", getErrorMsg(error));
  }
};

export const sendTTQCheckoutEvent = async (
  emailAddress: string,
  phoneNumber: string,
  subscription: ViewWLSubscriptionsTiers,
  tiktokSource: string | null,
  validStripeCoupon: undefined | null | Stripe.Coupon
) => {
  try {
    const hashedEmail = await hashStringToSHA256(emailAddress);
    const hashedPhone = await hashStringToSHA256(phoneNumber);

    const value =
      validStripeCoupon !== undefined && !isNull(validStripeCoupon)
        ? getStripePriceValueWithPromo(subscription.price, validStripeCoupon)
        : subscription.price;

    await Promise.all([
      ttqTrackEvent({
        event: "AddPaymentInfo",
        user: {
          email: hashedEmail,
          phone: hashedPhone,
          ...(!isNull(tiktokSource)
            ? {
                ttclid: tiktokSource,
              }
            : {}),
        },
        product: {
          id: subscription.docId,
          name: subscription.name,
          value,
        },
        url: location.href,
      }),

      ttqTrackEvent({
        event: "InitiateCheckout",
        user: {
          email: hashedEmail,
          phone: hashedPhone,
          ...(!isNull(tiktokSource)
            ? {
                ttclid: tiktokSource,
              }
            : {}),
        },
        product: {
          id: subscription.docId,
          name: subscription.name,
          value,
        },
        url: location.href,
      }),
    ]);
  } catch (error) {
    bugNotif("sendTTQCheckoutEvent", getErrorMsg(error));
  }
};

export const sendTTQCompletedPurchaseEvent = async (
  userId: string,
  value: number,
  subscriptionId: string,
  tiktokSource: string | null,
  completePaymentEventId: string
) => {
  try {
    const {
      data: {
        result: { emailHashed, phoneNumberHashed },
      },
    } = await getUserHashedDetails(userId);

    const subscription = await getSubscription(subscriptionId);
    if (isNull(subscription)) {
      return;
    }
    await Promise.all([
      ttqTrackEvent({
        event: "CompletePayment",
        event_id: completePaymentEventId,
        user: {
          email: emailHashed,
          phone: phoneNumberHashed,
          ...(!isNull(tiktokSource)
            ? {
                ttclid: tiktokSource,
              }
            : {}),
        },
        product: {
          id: subscription.docId,
          name: subscription.name,
          value,
        },
        url: location.href,
      }),

      ttqTrackEvent({
        event: "CompleteRegistration",
        user: {
          email: emailHashed,
          phone: phoneNumberHashed,
          ...(!isNull(tiktokSource)
            ? {
                ttclid: tiktokSource,
              }
            : {}),
        },
        product: {
          id: subscription.docId,
          name: subscription.name,
          value,
        },
        url: location.href,
      }),
    ]);
  } catch (error) {
    bugNotif("sendTTQCompletedPurchaseEvent", getErrorMsg(error));
  }
};

export const trackWebSignUpStep = async (
  emailAddress: string,
  step: WebSignUpSteps
) => {
  try {
    await trackWebSignUpSteps({
      emailAddress,
      step,
    });
  } catch (error) {
    console.log("silent error tracking sign up step");
    bugNotif("trackWebSignUpSteps", getErrorMsg(error));
  }
};
